import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { format } from "date-fns";
import React from "react";

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    data?: any;
}

const TableDialog = ({ onClose, open, data }: SimpleDialogProps) => {
    const dialogData: any[] = [];
    if (data) {
        for (const key in data) {
            if (key === "created_at" || key === "updated_at") {
                dialogData.push({ key: key, label: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " "), value: format(new Date(data[key]), "d MMM yyyy h:mm") });
            } else {
                dialogData.push({ key: key, label: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " "), value: data[key] });
            }
        }
    }

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Details</DialogTitle>
            <DialogContent>
                {dialogData &&
                    dialogData.map((item: any) => {
                        return (
                            <div className="row" key={item.key}>
                                <div className="col-4">{item.label}</div>
                                <div className="col-8">{item.value}</div>
                            </div>
                        );
                    })}
            </DialogContent>
        </Dialog>
    );
};

export default TableDialog;
