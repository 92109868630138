import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./styles/fonts.css";
import "./styles/color.css";
import "./styles/global.css";

import HomePage from "./pages/home/home";
import DashboardPage from "./pages/dashboard/dashboard";
import { coreService } from "./services/core.service";
import Form from "./pages/form/form";
import Sidebar from "./includes/sidebar/sidebar";
import AccountPage from "./pages/account/account";
import MobileNav from "./includes/mobile-nav/mobile-nav";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CompanyPage from "./pages/company/company";
import ProfilePage from "./pages/profile/profile";

//Polyfill for .replaceAll method so that the site works on old OS
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str: any, newStr: any){

		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

function App() {
    const [userLogin, setUserLogin] = useState<boolean>(false);

    const theme = createTheme({
        typography: {
            fontFamily: `"Rubik", "Roboto", "Helvetica", "Arial", sans-serif`,
            button: {
                textTransform: "none",
            },
        },
        shape: {
            borderRadius: 10,
        },
        palette: {
            primary: {
                main: "#00322f",
            },
            secondary: {
                main: "#00A298",
            },
        },
    });

    useEffect(() => {
        const token = coreService.GetTokenFromLocalStorage();
        if (token) {
            setUserLogin(true);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div className="d-flex app-container">
                    <Sidebar currentUser={userLogin} />
                    <div className="position-relative bgc-light flex-grow-1 content-container">
                        <MobileNav currentUser={userLogin} />
                        <div className="p-4 app-content">
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/dashboard" element={userLogin ? <DashboardPage /> : <HomePage />} />
                                <Route path="/company/:company_id" element={userLogin ? <CompanyPage /> : <HomePage />} />
                                <Route path="/company/:company_id/form/:form_id" element={userLogin ? <Form /> : <HomePage />} />
                                <Route path="/account" element={userLogin ? <AccountPage /> : <HomePage />} />
                                <Route path="/profile" element={userLogin ? <ProfilePage /> : <HomePage />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
