import { useCallback, useEffect, useState } from "react";
import EnhancedTable from "../../components/mui-table/mui-table";
import ExportCsv from "../../components/export-csv/export-csv";
import TableDialog from "../../components/table-dialog/table-dialog";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { useParams } from "react-router-dom";
import ApiService from "../../services/api.service";
import "./form.styles.css";
import TableSearch from "../../components/table-search/table-search";
import PopupMessageComponent from "../../components/popup-message/popup-message";
import ManageUsers from "../../components/manage-users/manage-users";
import ManageFormOptions from "../../components/manage-form-options/manage-form-options";
import TableSort from "../../components/table-sort/table-sort";
import Settings from "../../components/settings/settings";
import CompanyFilter from "../../components/company-filter/company-filter";
import ManageBlockedKeywords from "../../components/manage-blocked-keywords/manage-blocked-keywords";
import FormDescription from "../../components/form-description/form-description";
import ManageEmailTemplates from "../../components/manage-email-templates/manage-email-templates";
import { coreService } from "../../services/core.service";
import { TextDecoder } from "util";
import moment from 'moment'
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Form = () => {
    const [userInfo, setUserInfo] = useState<any>(null);
    const [userForms, setUserForms] = useState<any[]>([]);
    const [originalDatas, setOriginalDatas] = useState<any[]>([]);
    const [filteredDatas, setFilteredDatas] = useState<any[]>([]);
    const [formInfo, setFormInfo] = useState<any>();
    const [formName, setFormName] = useState<string>("");
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<null | { [index: string]: any }>(null || {});
    const [loading, setLoading] = useState<boolean>(false);
    const [tableController, setTableController] = useState<any>({
        page: 0,
        rowsPerPage: 100
    })
    const [popupMessage, setPopupMessage] = useState({
        title: "",
        content: "",
    });

    const isMobile = useMediaQuery('(max-width: 767px)')

    const { form_id, company_id } = useParams();

    const getApiData = useCallback(() => {
        ApiService.getDataById(form_id, (tableController.page * tableController.rowsPerPage) + 1, (tableController.page + 1) * tableController.rowsPerPage).then(
            (res) => {
                setLoading(false);
                setFilteredDatas(res.data.result.data);
                setOriginalDatas(res.data.result.data);
            },
            (err) => {
                console.error(err);
                setPopupOpen(true);
                setPopupMessage({
                    title: "Oops...",
                    content: "Some errors occurred while getting the form data. Please try again later.",
                });
            }
        );

        ApiService.getFormInfoById(form_id).then(
            (res) => {
                setFormInfo(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [form_id, tableController]);

    useEffect(() => {
        setLoading(true);
        setFormName("form" + form_id);
        getApiData();
    }, [getApiData, form_id]);

    useEffect(() => {
        coreService.userData.subscribe((res) => {
            setUserInfo(res);
        });

        coreService.userForm.subscribe((res) => {
            res[0] && setUserForms(res);
        });
    }, []);

    useEffect(() => {
        formInfo && (document.title = "Forms Portal | " + formInfo.name)
    }, [formInfo]);

    const tableHeader: any[] = [];
    if (filteredDatas && filteredDatas[0]) {
        for (const key in filteredDatas[0]) {
            if (key !== "ip_address" && key !== "status_id" && key !== "created_at" && key !== "updated_at" && key !== "deleted_at") {
                tableHeader.push({ id: key, numeric: false, label: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " "), render: (data: any) => <div>{data.row[key]}</div> });
            }
        }
    }

    const sortOptions: any[] = [];
    if (filteredDatas && filteredDatas[0]) {
        for (const key in filteredDatas[0]) {
            if (key !== "ip_address" && key !== "status_id" && key !== "updated_at" && key !== "deleted_at") {
                sortOptions.push({ id: key, value: key, name: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ").replaceAll("Created at", "Date") });
            }
        }
    }

    const handleDeleteRow = (row_id: number) => {
        var deleteRowData = new URLSearchParams()
        deleteRowData.append('form_id', form_id ? form_id : '')
        deleteRowData.append('record_id', row_id.toString())

        ApiService.deleteFormRow(deleteRowData)
            .then((res) => {
                console.log(res)
                setFilteredDatas(filteredDatas.filter((row: any) => row.id != row_id))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const newTableHeader = tableHeader;
    
        newTableHeader.push({
            id: "created_at",
            numeric: false,
            label: "Date",
            render: (data: any) => 
                 <span>
                    {moment(data.row.created_at).local().format('YYYY-MM-DD')}
                </span>
            ,
        });

    newTableHeader.push({
        id: "view",
        numeric: false,
        label: "",
        render: (data: any) => (
            <span
                onClick={() => {
                    console.log(data)
                    handleDeleteRow(data.row.id)
                }}
                className="link-secondary cursor-pointer"
            >
                Delete
            </span>
        ),
    });

    return (
        <>
            <div className="px-md-2">
                {loading && <LoadingSpinner />}
                {!isMobile && <div className="d-flex justify-content-between mt-3">
                    <CompanyFilter />
                    <Settings />
                </div>}
                {formInfo && formInfo.name && <div className={isMobile ? "" : "my-4"}>
                    <div className="fs-24 fw-500 mb-2">{formInfo.name}</div>
                    {formInfo.description && <FormDescription description={formInfo.description}/>}
                </div>}
                {(formInfo && filteredDatas && filteredDatas.length) ? (
                    <>
                        {/* Form Top Bar */}
                        <div className="d-flex flex-column justify-content-between">
                            {/* Tool Bar */}
                            {(isMobile && userForms.map((form) => form.id).indexOf(form_id) == -1) &&
                                <Accordion>
                                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                        Actions
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="d-flex flex-column mb-4">
                                            <ManageBlockedKeywords company_id={company_id}/>
                                            <ManageFormOptions form_info={formInfo} />
                                            <ManageEmailTemplates form_info={formInfo} />
                                            <ManageUsers form_info={formInfo} />
                                        </div>
                                        <TableSort form_id={form_id} sortOptions={sortOptions} setFilteredDatas={setFilteredDatas} setLoading={setLoading} />
                                        {/* Search Bar */}
                                        <div className="mb-2">
                                            <TableSearch setFilteredDatas={setFilteredDatas} originalDatas={originalDatas} headers={newTableHeader} />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            <div className="d-flex align-items-center flex-wrap form-tools">
                                {!isMobile && <ExportCsv data={filteredDatas} formName={formName} />}
                                {/* Checking that user isn't access the form as a User and not a company admin */}
                                    {(userForms.map((form) => form.id).indexOf(form_id) == -1 && !isMobile) && 
                                        <>
                                                <ManageBlockedKeywords company_id={company_id}/>
                                                <ManageFormOptions form_info={formInfo} />
                                                <ManageEmailTemplates form_info={formInfo} />
                                            <div className="d-flex me-4">
                                                <ManageUsers form_info={formInfo}/>
                                            </div>
                                            <div className="d-flex">
                                                {/* Search Bar */}
                                                <TableSort form_id={form_id} sortOptions={sortOptions} setFilteredDatas={setFilteredDatas} setLoading={setLoading} />
                                                <TableSearch setFilteredDatas={setFilteredDatas} originalDatas={originalDatas} headers={newTableHeader} />
                                            </div>
                                        </>
                                    }
                            </div>
                        </div>

                        {/* Form */}
                        <EnhancedTable header={newTableHeader} tableDatas={filteredDatas} setDialogData={setDialogData} tableController={tableController} setTableController={setTableController} totalRows={formInfo.total_records_count} loading={loading} setLoading={setLoading} />

                        {/* Form Row Details */}
                        <TableDialog open={dialogOpen} onClose={() => setDialogOpen(false)} data={dialogData} />
                    </>
                ) : loading ? (
                    "Loading..."
                ) : (
                    "No data"
                )}
                {/* Error Message */}
                <PopupMessageComponent message={popupMessage} open={popupOpen} setOpen={setPopupOpen} />
            </div>
        </>
    );
};

export default Form;
