import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useMediaQuery } from "@mui/material";

export interface FormDescriptionProps {
    description: string;
}

const FormDescription = ({ description }: FormDescriptionProps) => {

    const [open, setOpen] = useState<Boolean>(false)
    const isMobile = useMediaQuery('(max-width: 767px)')

    var textCharLimit = 50
    var shortDescription = description.slice(0, textCharLimit).replace(/<[^>]*>?/gm, '') + (description.length > textCharLimit ? '...' : '')

    return (
        <div className="d-flex flex-column" style={isMobile ? {width: 'calc(100vw - 3rem)'} : {}}>
            <div className="d-flex">
                <InfoIcon fontSize="small"/>
                {open
                    ? <div className="fs-14 description-text mx-1 mb-2" dangerouslySetInnerHTML={{ __html: description }} style={{wordBreak: "break-word"}}/>
                    : <div onClick={() => {setOpen(!open)}} className="fs-14 description-text mx-1 mb-2" dangerouslySetInnerHTML={{ __html: shortDescription }}/>
                }
            </div>
            <a className="fs-14 cursor-pointer description-link" onClick={() => {setOpen(!open)}}>
                {description.length > textCharLimit && (open ? 'See Less' : 'See More')}
            </a>
        </div>
    );
};

export default FormDescription;
