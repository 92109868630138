import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: any) {
    const { order, orderBy, header } = props;

    return (
        <TableHead>
            <TableRow>
                {header &&
                    header.length &&
                    header.map((headCell: any) => (
                        <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={"normal"} sortDirection={orderBy === headCell.id ? order : false}>
                            {headCell.label}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}
export interface TableInterface {
    tableDatas: any;
    header: {}[];
    deleteCall?: (id: any) => any;
    editCall?: (id: any) => any;
    setDialogData?: any;
    tableController: any;
    setTableController: any;
    totalRows: number;
    loading: Boolean;
    setLoading: any;
}

export default function EnhancedTable({ tableDatas, header, deleteCall, editCall, setDialogData, tableController, setTableController, totalRows, loading, setLoading }: TableInterface) {
    const currentTableData = tableDatas;
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const tableRef = React.useRef(null)
    const isMobile = useMediaQuery('(max-width: 767px)')

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setTableController({
            ...tableController,
            page: newPage
        });
        setLoading(true)
    };

    const handleChangeRowsPerPage = (event: any) => {
        setTableController({
            ...tableController,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
        setLoading(true)
    };

    return (
        <div className="w-100 mt-3">
            {currentTableData && currentTableData.length ? (
                <>
                    <Paper ref={tableRef} sx={{ width: "100%", mb: 2 }}>
                        <TableContainer sx={{...{height: 'calc(98vh - 22rem)'}, ...(isMobile && {width: 'calc(100vw - 3rem)', overflowX: 'auto'})}}>
                            <Table aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table" stickyHeader>
                                <EnhancedTableHead header={header} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={currentTableData.length} />
                                <TableBody>
                                    {loading ? <tr>Loading...</tr>
                                    : stableSort(currentTableData, getComparator(order, orderBy))
                                        .map((row: any, index: number) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                    {header.map((data: any, index: number) => {
                                                        const Render = data.render;
                                                        return (
                                                            <TableCell key={index} align="left">
                                                                <Render row={row} />
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination rowsPerPageOptions={[100, 200, 500]} labelRowsPerPage={isMobile ? "Rows:" : "Rows per page:"} component="div" count={totalRows} rowsPerPage={tableController.rowsPerPage} page={tableController.page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Paper>
                </>
            ) : (
                ""
            )}
        </div>
    );
}
