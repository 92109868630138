import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiService from "../../services/api.service";
import PopupMessageComponent from "../popup-message/popup-message";
import KeywordsDialog from "./keywords-dialog";

export interface Props {
    company_id: string | undefined;
}

const ManageBlockedKeywords = ({ company_id }: Props) => {

    const [blockedKeywords, setBlockedKeywords] = useState()
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [popupMessage, setPopupMessage] = useState({
        title: "",
        content: "",
    });

    const handleClick = () => {
        setDialogOpen(true);
    };

    useEffect(()=>{
        ApiService.getBlockedKeywordsByCompanyId(company_id).then(
            (res) => {
                setBlockedKeywords(res.data.result.data);
            },
            (err) => {
                console.error(err);
                setPopupOpen(true);
                setPopupMessage({
                    title: "Oops...",
                    content: "Some errors occurred. Please try again later.",
                });
            }
        )
    }, [company_id])

    return (
        <>
            <div className="mx-1 mb-2">
                <Button variant="outlined" color="secondary" size="large" onClick={handleClick} fullWidth>
                    Blocked Keywords
                </Button>
                <KeywordsDialog open={dialogOpen} setDialogOpen={() => setDialogOpen(false)} data={blockedKeywords} company_id={company_id}/>
            </div>
            <PopupMessageComponent message={popupMessage} open={popupOpen} setOpen={setPopupOpen} />
        </>
    );
};

export default ManageBlockedKeywords;
