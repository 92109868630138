import axios from "axios";
import { apiUrl } from "../environment";
import { coreService } from "./core.service";

export const authAxios =
    coreService.GetTokenFromLocalStorage() !== null
        ? axios.create({
              baseURL: apiUrl,
              headers: { Authorization: `Bearer ${coreService.GetTokenFromLocalStorage()}` },
          })
        : axios.create({ baseURL: apiUrl });
