import { TextField } from "@mui/material";
import React from "react";

interface Props {
    setFilteredDatas: (filteredDatas: any) => void;
    originalDatas: any[];
    headers: any[];
}
const TableSearch = ({ setFilteredDatas, originalDatas, headers }: Props) => {

    const handleSearch = (e: any) => {
        const { value } = e.target;
        
        const conditionCheck = (a:any) => {
            let conditionMatch = false;
            headers.forEach((header) => {
                conditionMatch = conditionMatch || (a[header.id] && a[header.id].toString().toLowerCase().includes(value.toLowerCase()))
            });
            return conditionMatch
        }

        const filteredDatas = originalDatas.filter((item: any) => conditionCheck(item));
        filteredDatas && filteredDatas.length && setFilteredDatas(filteredDatas);
    };

    return (
        <div className="mx-1 mb-2 d-flex align-items-center">
            <TextField label="Search" variant="outlined" size="small" onChange={handleSearch} fullWidth/>
        </div>
    );
};

export default TableSearch;
