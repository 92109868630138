import { useState } from "react";
import { Dialog, DialogContent, DialogTitle, TextField, Tabs, Tab, useMediaQuery, Button } from "@mui/material";
import ApiService from "../../services/api.service";
import TabPanel from "./manage-email-tab-panel";

export interface SimpleDialogProps {
    open: boolean;
    data?: any;
    setDialogOpen: (dialogOpen: boolean) => void;
}

const ManageEmailDialog = ({ open, data, setDialogOpen }: SimpleDialogProps) => {
    const [tabValue, setTabValue] = useState(0)
    const [formValues, setFormValues] = useState<{ [index: string]: any }>({ id: data ? data.id : "" });
    const isMobile = useMediaQuery('(max-width: 767px)')

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const openEmailTemplate = (role: string) => {
        //Open empty window
        var child = window.open('about:blank', '_blank')
        var template = ''

        if(role == 'User'){
            template = formValues.email_user_body_html ? formValues.email_user_body_html : data ? data.email_user_body_html : ''
        } else if(role == 'Admin') {
            template = formValues.email_admin_body_html ? formValues.email_admin_body_html : data ? data.email_admin_body_html : ''
        }
        //Populate window with content based on current role selected
        child?.document.write(template)
    }

    const handleTabChange = (e: any, newValue: number) => {
        setTabValue(newValue)
    }


    const handleSubmit = () => {

        const transmissionData = new URLSearchParams();
        for (const name in formValues) {
            transmissionData.append(name, formValues[name] ? formValues[name] : "");
        }

        ApiService.updateFormInfo(transmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    return (
        <Dialog onClose={() => setDialogOpen(false)} open={open} fullWidth maxWidth="md">
            <DialogTitle>Manage Email Template</DialogTitle>
            <DialogContent>
                <div className="row my-4">
                    {/* <div className="col-3">Email From Name</div> */}
                    <div>
                        <TextField 
                            fullWidth
                            label="Email From Name"
                            name="email_from_name" 
                            variant="standard" 
                            defaultValue={formValues.email_from_name ? formValues.email_from_name : data ? data.email_from_name : ''} 
                            onChange={handleFormChange} 
                        />
                    </div>
                </div>
                <div className="row my-4">
                    {/* <div className="col-3">Email From Address</div> */}
                    <div>
                        <TextField 
                            fullWidth 
                            label="Email From Address"
                            name="email_from_address" 
                            variant="standard" 
                            defaultValue={formValues.email_from_address ? formValues.email_from_address : data ? data.email_from_address : ''} 
                            onChange={handleFormChange} 
                        />
                    </div>
                </div>

                {!isMobile ? 
                <>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label={'Admin'} />
                        <Tab label={'User'} />
                    </Tabs>
                    <TabPanel 
                        value={tabValue} 
                        index={0}
                        role='Admin'
                        defaultSubject={formValues.email_admin_subject ? formValues.email_admin_subject : data ? data.email_admin_subject : ''}
                        defaultBody={formValues.email_admin_body_html ? formValues.email_admin_body_html : data ? data.email_admin_body_html : ''}
                        onChange={handleFormChange}
                        openEmailTemplate={openEmailTemplate}
                        handleSubmit={handleSubmit}
                        setDialogOpen={setDialogOpen}
                    />        
                    <TabPanel 
                        value={tabValue} 
                        index={1}
                        role='User'
                        defaultSubject={formValues.email_user_subject ? formValues.email_user_subject : data ? data.email_user_subject : ''}
                        defaultBody={formValues.email_user_body_html ? formValues.email_user_body_html : data ? data.email_user_body_html : ''}
                        onChange={handleFormChange}
                        openEmailTemplate={openEmailTemplate}
                        handleSubmit={handleSubmit}
                        setDialogOpen={setDialogOpen}
                    />
                </>
                :
                <div className="mt-5 d-flex justify-content-between">
                    <Button variant="outlined" color="error" size="large" onClick={() => setDialogOpen(false)}>
                        Close
                    </Button>
                    <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                    Save
                    </Button>
                </div>
                }
            </DialogContent>
        </Dialog>
    );
};

export default ManageEmailDialog;
