import { useEffect, useState } from "react";
import CompanyFilter from "../../components/company-filter/company-filter";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import Settings from "../../components/settings/settings";
import { coreService } from "../../services/core.service";

const DashboardPage = () => {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        coreService.userCompany.subscribe((res) => {
            res[0] && window.location.replace("/company/" + res[0].id);
        });
        document.title = "Forms Portal | Dashboard"
    }, []);

    return (
        <>
            <div className="place-holder"></div>
            <div className="px-md-4">
                {loading && <LoadingSpinner />}
                <div className="d-flex justify-content-between">
                    <CompanyFilter />
                    <Settings />
                </div>
            </div>
        </>
    );
};

export default DashboardPage;
