import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import ApiService from "../../services/api.service";

interface Props {
    data: any;
    formName: string;
}

const ExportCsv = ({ data, formName }: Props) => {

    const [formData, setFormData] = useState([])
    const { form_id, company_id } = useParams();

    useEffect(() => {
        getFormData()
    }, [])

    const getFormData = () => {
        ApiService.getDataById(form_id, 1, 999999999).then(
            (res) => {
                setFormData(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        )
    }

    const csvHeaders: any = [];
    if (data && data[0]) {
        for (const key in data[0]) {
            if (key !== "ip_address" && key !== "status_id" && key !== "updated_at" && key !== "deleted_at") {
                csvHeaders.push({ label: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ").replace("Created at", "Date"), key: key });
            }
        }
    }

    return (
        <>
            <CSVLink filename={"export_" + formName + "_" + Date.now()} headers={csvHeaders} data={formData} className="text-decoration-none mx-1 mb-2">
                <Button variant="contained" size="large" disabled={!formData.length}>
                    Export to CSV
                </Button>
            </CSVLink>
        </>
    );
};

export default ExportCsv;
