import { Button, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import { coreService } from "../../services/core.service";
import SettingsIcon from '@mui/icons-material/Settings';

const Settings = () => {

    const isMobile = useMediaQuery('(max-width: 767px)')

    return (
        <>
            {!isMobile ? 
                <div className="d-flex gap-3">
                    <IconButton href="/profile">
                        <SettingsIcon fontSize="large"/>
                    </IconButton>
                    <Button variant="outlined" size="large" onClick={() => coreService.LogOut()}>
                        Log out
                    </Button>
                </div>
                :
                <>
                </>
            }
        </>
    );
};

export default Settings;
