import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingSpinner = () => {
    return (
        <div className="position-absolute full-screen">
            <div className="position-center">
                <CircularProgress color="secondary" />
            </div>
        </div>
    );
};

export default LoadingSpinner;
