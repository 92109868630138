import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

export interface Props {
    message: {
        title: string;
        content: string;
    };
    open: boolean;
    setOpen: (open: boolean) => void;
}

const PopupMessageComponent = ({ message, open, setOpen }: Props) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{message.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PopupMessageComponent;
