import { Button } from "@mui/material";
import React, { useState } from "react";
import ManageEmailDialog from "./manage-email-dialog";

export interface Props {
    form_info: any;
}

const ManageEmailTemplates = ({ form_info }: Props) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    return (
        <div className="mx-1 mb-2">
            <Button variant="outlined" color="secondary" size="large" onClick={handleClick} fullWidth>
                Email Options
            </Button>
            <ManageEmailDialog open={dialogOpen} data={form_info} setDialogOpen={setDialogOpen} />
        </div>
    );
};

export default ManageEmailTemplates;
