import { Alert, Button, Collapse, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Settings from "../../components/settings/settings";
import ApiService from "../../services/api.service";
import { coreService } from "../../services/core.service";
import CloseIcon from '@mui/icons-material/Close';

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState<any>(null);

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [warningText, setWarningText] = useState('')
    const [successOpen, setSuccessOpen] = useState(false)

    useEffect(() => {
        coreService.userData.subscribe((res) => {
            setUserInfo(res);
        });
    }, []);

    useEffect(() => {
        if(newPassword.length < 8) {
            setWarningText('Password must be at least 8 characters')
        } else if(!['!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', '\\', ']', '^', '_', '`', '{', '|', '}', '~'].some(x => newPassword.includes(x))) {
            setWarningText('Password must contain at least one special character')
        } else if(!/[a-z]/.test(newPassword)) {
            setWarningText('Password must contain at least one lowercase letter')
        } else if(!/[A-Z]/.test(newPassword)) {
            setWarningText('Password must contain at least one uppercase letter')
        } else if(newPassword !== confirmPassword) {
            setWarningText('Passwords do not match')
        } else {
            setWarningText('')
        }
    }, [newPassword, confirmPassword])

    const handleSubmit = (e: any) => {
        var passwordUpdateData = new URLSearchParams()

        passwordUpdateData.append('old_password', oldPassword)
        passwordUpdateData.append('new_password', newPassword)

        if(newPassword === confirmPassword){
            ApiService.updateUserPassword(passwordUpdateData)
                .then((res) => {
                    setSuccessOpen(true)
                    setOldPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                })
                .catch((err) => {
                    alert('There was a problem updating your password. Please try again.')
                    console.log(err)
                })
        }
    }

    return (
        <>
            <Collapse in={successOpen}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    Password Updated Successfully
                </Alert>
            </Collapse>
            
            <div className="d-flex justify-content-end">
                <Settings />
            </div>
            
            {userInfo && <>
                <div className="mb-5">
                    <h2>{userInfo.name}</h2>
                    <h5>{userInfo.email}</h5>
                </div>
                <div className="update-user-password">
                    <h4>Update Password</h4>
                    <div className="mt-4 col-sm-12 col-md-6">
                        <div className="d-flex flex-column account-update-password-form form">
                            <TextField className="mb-4" type={'password'} value={oldPassword} label="Old Password" onChange={(e: any) => setOldPassword(e.target.value)} autoComplete="new-password"/>
                            <TextField className="mb-4" type={'password'} value={newPassword} label="New Password" onChange={(e: any) => setNewPassword(e.target.value)} autoComplete="new-password"/>
                            <TextField className="mb-4" type={'password'} value={confirmPassword} label="Confirm Password" onChange={(e: any) => setConfirmPassword(e.target.value)} autoComplete="new-password"/>
                            <small className="mb-4">{warningText}</small>
                            <Button variant="contained" onClick={handleSubmit} disabled={warningText.length > 0}>Update Password</Button>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
};

export default ProfilePage;
