import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import "./sidebar.styles.css";
import ApiService from "../../services/api.service";
import { coreService } from "../../services/core.service";

interface Props {
    currentUser: boolean;
}

const Sidebar = ({ currentUser }: Props) => {
    const { pathname } = useLocation();

    const [companyForms, setCompanyForms] = useState<any[]>([]);
    const [userForms, setUserForms] = useState<any[]>([]);
    const [pathArray, setPathArray] = useState<any[]>([]);

    const getApiData = (company_id: number | string) => {
        ApiService.getCompanyForm(company_id).then(
            (res) => {
                setCompanyForms(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    useEffect(() => {
        const pathArray = pathname.split("/");
        setPathArray(pathArray);
        if (pathArray.includes("company")) {
            let company_id = pathArray[2];
            company_id != "0" && getApiData(company_id);
        }

        coreService.userForm.subscribe((res) => {
            res[0] && setUserForms(res);
        });
    }, [pathname]);

    return (
        <div className="main bgc-linear text-white d-none d-md-block">
            <div className="w-100 d-flex flex-column flex-shrink-0">
                <div className="p-4 link-white text-decoration-none fs-18">
                    <img src={Logo} alt="logo" className="me-2 logo" />
                    Forms Portal
                </div>
                {currentUser && (
                    <>
                        <ul className="list-unstyled mb-auto">
                            {/* Dashboard */}
                            <li className={`mb-1 px-4 py-2 sidebar-list ${pathname.includes("dashboard") ? "active" : ""}`}>
                                <a className="d-block link-white text-decoration-none fs-16 lh-2" href="/dashboard">
                                    Dashboard
                                </a>
                            </li>

                            {/* Company Form Overview */}
                            {companyForms && companyForms.length ? (
                                <li className={`mb-1 px-4 py-2 sidebar-list ${pathname.includes("company") && "active"}`}>
                                    <div className="d-block link-white text-decoration-none fs-16 lh-2">Company Forms</div>
                                </li>
                            ) : (
                                ""
                            )}

                            {/* Company Forms */}
                            <div className="forms-title-container">
                                {companyForms && companyForms.length
                                    ? companyForms.map((item: any) => {
                                          return (
                                              <li className={`mb-1 px-4 py-2 sidebar-sublist ${pathArray[4] === item.id && "active"}`} key={item.id}>
                                                  <a className="d-block ps-4 link-white text-decoration-none fs-16 lh-2" href={`/company/${item.company_id}/form/${item.id}`}>
                                                      {item.name}
                                                  </a>
                                              </li>
                                          );
                                      })
                                    : ""}
                            </div>

                            {/* User Form Overview */}
                            {userForms && userForms.length ? (
                                <li className={`mb-1 px-4 py-2 sidebar-list ${pathname.includes("company") && "active"}`}>
                                    <div className="d-block link-white text-decoration-none fs-16 lh-2">User Forms</div>
                                </li>
                            ) : (
                                ""
                            )}

                            {/* User Forms */}
                            <div className="forms-title-container">
                                {userForms && userForms.length
                                    ? userForms.map((item: any) => {
                                          return (
                                              <li className={`mb-1 px-4 py-2 sidebar-sublist ${pathArray[4] === item.id && "active"}`} key={item.id}>
                                                  <a className="d-block ps-4 link-white text-decoration-none fs-16 lh-2" href={`/company/${item.company_id}/form/${item.id}`}>
                                                      {item.name.length > 20 ? item.name.substring(0, 20) + " ..." : item.name}
                                                  </a>
                                              </li>
                                          );
                                      })
                                    : ""}
                            </div>
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
