import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import "./mobile-nav.styles.css";
import ApiService from "../../services/api.service";
import { coreService } from "../../services/core.service";

interface Props {
    currentUser: boolean;
}

const Nav = ({ currentUser }: Props) => {
    const { pathname } = useLocation();

    const [companyForms, setCompanyForms] = useState<any[]>([]);
    const [userForms, setUserForms] = useState<any[]>([]);
    const [pathArray, setPathArray] = useState<any[]>([]);

    const getApiData = (company_id: number | string) => {
        ApiService.getCompanyForm(company_id).then(
            (res) => {
                setCompanyForms(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    useEffect(() => {
        const pathArray = pathname.split("/");
        setPathArray(pathArray);
        if (pathname.includes("/company/")) {
            let company_id = pathname.replace("/company/", "");
            company_id != "0" && getApiData(company_id);
        }

        coreService.userForm.subscribe((res) => {
            res[0] && setUserForms(res);
        });
    }, [pathname]);

    return (
        <>
            <div className="bgc-linear position-fixed w-100 zindex-5">
                <nav className="text-white d-block d-md-none">
                    <div className="w-100 d-flex justify-content-between align-items-center p-4">
                        <div>
                            <img src={Logo} alt="logo" className="me-2 logo-mobile" />
                            Forms Portal
                        </div>
                        {currentUser && (
                            <div>
                                <div data-bs-toggle="collapse" data-bs-target="#nav-dropdown" aria-expanded="false" aria-controls="nav-dropdown">
                                    <i className="fa fa-bars hamburger"></i>
                                </div>
                            </div>
                        )}
                    </div>
                </nav>
                <div className="collapse" id="nav-dropdown">
                    <div className="text-white dropdown-container">
                        <ul className="list-unstyled mb-auto">
                            {/* Dashboard */}
                            <li className={`mb-1 py-2 px-4 sidebar-list ${pathname.includes("dashboard") && "active"}`}>
                                <a className="d-block link-white text-decoration-none fs-16 lh-2" href="/dashboard">
                                    Dashboard
                                </a>
                            </li>

                            {/* Form Overview */}
                            {companyForms.length > 0 &&
                                <li className={`mb-1 py-2 px-4 sidebar-list ${pathname.includes("company") && "active"}`}>
                                    <div className="d-block link-white text-decoration-none fs-16 lh-2">Company Forms</div>
                                </li>
                            }

                            {companyForms.length > 0 &&
                                companyForms.map((item: any) => {
                                    return (
                                        <li className={`mb-1 py-2 px-4 sidebar-sublist ${pathArray[4] === item.id && "active"}`} key={item.id}>
                                            <a className="d-block ps-4 link-white text-decoration-none fs-16 lh-2" href={`/company/${item.company_id}/form/${item.id}`}>
                                                {item.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }

                            {/* Form Overview */}
                            {userForms.length > 0 &&
                                <li className={`mb-1 py-2 px-4 sidebar-list ${pathname.includes("company") && "active"}`}>
                                    <div className="d-block link-white text-decoration-none fs-16 lh-2">User Forms</div>
                                </li>
                            }

                            {userForms.length > 0 && 
                                userForms.map((item: any) => {
                                    return (
                                        <li className={`mb-1 py-2 px-4 sidebar-sublist ${pathArray[4] === item.id && "active"}`} key={item.id}>
                                            <a className="d-block ps-4 link-white text-decoration-none fs-16 lh-2" href={`/company/${item.company_id}/form/${item.id}`}>
                                                {item.name.length > 30 ? item.name.substring(0, 30) + " ..." : item.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }

                            <li className={`mb-1 py-2 px-4 sidebar-list ${pathname.includes("profile") && "active"}`} onClick={() => window.location.assign('/profile')}>
                                <div className="d-block link-white text-decoration-none fs-16 lh-2">Settings</div>
                            </li>

                            <li className={`mb-1 py-2 px-4 sidebar-list`} onClick={() => coreService.LogOut()}>
                                <div className="d-block link-white text-decoration-none fs-16 lh-2">Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nav;
