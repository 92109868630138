import { FormControlLabel, Switch, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyCard from "../../components/company-card/company-card";
import CompanyFilter from "../../components/company-filter/company-filter";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import Settings from "../../components/settings/settings";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import ApiService from "../../services/api.service";
import { coreService } from "../../services/core.service";

const CompanyPage = () => {
    const { company_id } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [filteredDatas, setFilteredDatas] = useState<any[]>([]);
    const [showFavourites, setShowFavourites] = useState(false)
    const [favourites, setFavourites] = useLocalStorage(`favourites${company_id}`, [])
    const isMobile = useMediaQuery('(max-width: 767px)')

    const getApiData = useCallback(() => {
        if(company_id =='0') {
            coreService.userForm.subscribe((res) => {
                setLoading(false);
                res[0] && setFilteredDatas(res)
            })
        } else {
            ApiService.getCompanyForm(company_id).then(
                (res) => {
                    setLoading(false);
                    setFilteredDatas(res.data.result.data.map((row: any) => { return { ...row, favourite: favourites.indexOf(row.id) > -1 }}));
                },
                (err) => {
                    console.error(err);
                }
            );
        }
    }, [company_id]);

    const handleFormFavouriteChange = (id: number) => {
        setFilteredDatas(filteredDatas.map((item: any) => item.id == id ? {...item, favourite: !item.favourite} : item))
    }

    useEffect(() => {
        setFavourites(filteredDatas.map((item: any) => item.favourite && item.id))
    }, [filteredDatas])

    useEffect(() => {
        setLoading(true);
        getApiData();
    }, [getApiData]);

    useEffect(() => {
        document.title = "Forms Portal | Dashboard"
    }, []);

    return (
        <>
            <div className="px-md-2">
                {loading && <LoadingSpinner />}
                <div className="d-flex justify-content-between mt-3 ">
                    <div className={`d-flex align-items-center gap-3 ${isMobile ? 'flex-column' : 'flex-row'}`}>
                        <CompanyFilter />
                        <FormControlLabel label="Show Favourites" control={<Switch color="secondary" value={showFavourites} onChange={() => setShowFavourites(!showFavourites)}/>}/>
                    </div>
                    <Settings/>
                </div>
                <div className="row mt-4">
                    {filteredDatas.length > 0 &&
                        filteredDatas
                            .filter((item: any) => 
                                showFavourites ? item.favourite : true
                            )
                            .map((item: any) => {
                                return <CompanyCard key={item.id} data={item} handleFavouriteChange={handleFormFavouriteChange} />;
                            })}
                </div>
            </div>
        </>
    );
};

export default CompanyPage;
